<script>
import {required, type} from '@/commons/utils/Rules';
import {getSelds} from "@/routes/api/resources";
import moment from "moment";
import baseFormFlujo from "@/components/Flujo/Templates/baseFormFlujo";

export default {
    name: 'PzBaseSimulador',
    components: {},
    mixins: [baseFormFlujo],
    data() {
        return {
            reference: 'form',
            hoy: '',
            elOfertaView: 'solicitud',
            monto_label: '',
            fechapago_label: '',
            form: {
                monto: this.producto.min_monto,
                plazo: this.producto.min_cuota,
                cuota_sugerida: '',
                monto_oferta: '',
                trabajadores: 1,
                fecha_vencimiento: '',
                tasa: null,
                emision: ''
            },
            workers: [],
            validation: {
                monto:
                    [
                        required(''),
                        type({field: '', type: 'number'}),
                    ],
                plazo:
                    [
                        required(''),
                        type({field: '', type: 'number'}),
                    ],
            },
            pickerOptions: {
                disabledDate(time) {
                    return time.getTime() < Date.now();
                },
            },
            pickerOptionsEmision: {
                disabledDate(time) {
                    return time.getTime() > Date.now();
                },
            },
        }
    },
    created() {
        this.fetchData();
    },
    methods: {
        emitChange() {
            this.$nextTick(() => {
                this.$emit('simular', this.form);
            });
        },
        handleInput() {
            this.$emit('input', this.form);
        },
        async setLabels() {
            if (this.producto.needsDocuments) {
                this.monto_label = this.$t('forms_flujo.PzSimulator.how_many_discount')
                this.fechapago_label = this.$t('forms_flujo.PzSimulator.limit_date');
                this.getOfertaView('descuentos');
            } else {
                this.monto_label = this.$t('forms_flujo.PzSimulator.how_many_cash');
                this.fechapago_label = this.$t('forms_flujo.PzSimulator.first_limit_date');
                this.getOfertaView('solicitud');
            }
        },
        getOfertaView(tipe) {
            this.elOfertaView = tipe + '-' + this.extraData.ofertaView;
        },
        async stablishDate() {
            if (this.producto.needsFirstDate) {
                var date = moment().add(1, 'W').format("DD-MM-YYYY");
                this.hoy = date;
                this.form.fecha_vencimiento = date;
                this.validation.fecha_vencimiento = [required('')]
                this.form.emision = date;
                this.validation.emision = [required('')]
                this.$emit('pick', new Date(date));
            }
        },
        async stablishSelds() {
            if (this.producto.needsSelds) {
                return getSelds().then((res) => {
                    this.$store.dispatch('loader/up', {trigger: this.$options.name});
                    this.workers = res.data;
                    this.validation.trabajadores = [required('')]
                }).catch(() => {
                    this.workers = [];
                }).finally(() => {
                    this.$store.dispatch('loader/down', {trigger: this.$options.name});
                });
            }
        },
        fetchData() {
            this.$store.dispatch('loader/up', {trigger: this.$options.name})
            let workers = this.stablishSelds();
            let date = this.stablishDate();
            let labels = this.setLabels();
            return Promise.all([workers, labels, date]).then(() => {
                this.emitChange();
            }).finally(() => {
                    this.$store.dispatch('loader/down', {trigger: this.$options.name})
                }
            );
        }
    },
}
</script>


